import React, { useState } from 'react';

import {useToast} from 'helpers/hooks';
import {Button} from 'components/Button';
import {Modal} from 'components/Modal';
import {request} from 'helpers/tools';
import styles from 'css_modules/Modal.module.css';


export const CaptureModal = ({close, docs, update}) => {
    const showToast = useToast();

    const [pageCount, setPageCount] = useState(-1);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [requestSent, setRequestSent] = useState(false);
    const [selectPages, setSelectPages] = useState(false);
    const [pageFrom, setPageFrom] = useState(1);
    const [pageTo, setPageTo] = useState(0);
    const [outpageFrom, setoutPageFrom] = useState(1);
    const [outpageTo, setoutPageTo] = useState(0);

    const handleChange = (event) => {setSelectPages(event.target.value==="off"?false:true);};
    
    let message = docs.length > 1 ? '' : 'Vytěžit dokument?';
    let buttonTitle = "Vytěžit"
    
    //captured uuids
    const capturedUuids = docs
    .filter(item => item.captured === true)
    .map(item => item.document_info?.document_identifier || item.uuid);
    //nr of docs already captured
    const countCaptured = capturedUuids.length
    
    //filter out already captured
    docs = docs.filter(item => item.captured !== true)

    let allDocs = docs.length

    if (allDocs>1){
        let ms = "Vytěžit vše"
        if (buttonTitle!==ms){buttonTitle=ms}
    }

    //nr of docs from isdoc
    const isdocUuids = docs
    .filter(item => item.captured_from_isdoc === true)
    .map(item => item.document_info?.document_identifier || item.uuid);
    //nr of docs from isdoc
    const countFromIsdoc = isdocUuids.length

    // docs regular
    const blankUuids = docs
    .filter(item => item.captured_from_isdoc === false)
    .map(item => item.document_info?.document_identifier || item.uuid);
    
  
    
    let multimessage = ""

    if (docs.length===1 && countFromIsdoc>0){
        multimessage += " Data již byla načtena z ISDOC."
        if (buttonTitle === "Vytěžit"){buttonTitle= "Přesto vytěžit"}
    }
    else if (docs.length>1 && countFromIsdoc>0){ 
        if (countFromIsdoc===1) { multimessage +=   "Jeden dokument již byl načten z ISDOC:"}
        else if (countFromIsdoc===2) { multimessage +=  "Dva dokumenty již byly načteny z ISDOC:"}
        else if (countFromIsdoc===3) { multimessage +=  "Tři dokumenty již byly načteny z ISDOC:"}
        else if (countFromIsdoc===4) { multimessage +=  "Čtyři dokumenty již byly načteny z ISDOC:"}
        else { multimessage +=  "" + countFromIsdoc + " dokumentů již bylo načteno z ISDOC:"}
        if (buttonTitle === "Vytěžit"){ (buttonTitle="Přesto vytěžit všechny ("+docs.length+")")}
    }

    let capturedMessage = ""
    if (countCaptured>0){
        //message="Vytěžit zbývající dokumenty? ("+docs.length+")"
        if (countCaptured===1) { capturedMessage +=   "Jeden dokument je vytěžený a bude přeskočen:"}
        else if (countCaptured===2) { capturedMessage +=  "Dva dokumenty jsou vytěženy a budou přeskočeny:"}
        else if (countCaptured===3) { capturedMessage +=  "Tři dokumenty jsou vytěženy a budou přeskočeny:"}
        else if (countCaptured===4) { capturedMessage +=  "Čtyři dokumenty jsou vytěženy a budou přeskočeny:"}
        else { capturedMessage +=  "" + countCaptured + " dokumentů je vytěženo a bude přeskočeno:"}
    }

    const handleClick = async () => {
        close();
        const url = '/my_documents/capture/';
        
        const data = {
            uuids: docs.map(doc => doc.uuid),
            pageFrom: -1,
            pageTo: -1 
          };
        
          
          if ( pageCount>1 && !(pageFrom===1 && pageTo===pageCount)){
              data["pageFrom"]=outpageFrom
              data["pageTo"]=outpageTo
          }
        
        const response = await request(url, 'POST', JSON.stringify(data));
        if (response.ok) {
            showToast('Odesláno k vytěžení');
            if (response.body){
                request('/delete_cropped/?uuid='+{uuids:docs.map(doc=>doc.uuid)})
            }
            
            
            update();
        } else {
            showToast('Došlo k chybě')
        }
         
    };

    const handleClickOnlyEmpty = async () => {
        close();
        const url = '/my_documents/capture/';
      
        const data = {
            uuids: blankUuids.map(doc => doc.uuid),
            pageFrom: -1,
            pageTo: -1 
          };
        
        
        if (pageCount>1 && pageFrom!==1 && pageTo!==pageCount){
            data["pageFrom"]=outpageFrom
            data["pageTo"]=outpageTo
        }

        const response = await request(url, 'POST', JSON.stringify(data));
        if (response.ok) {
            showToast('Odesláno k vytěžení');
            if (response.body){
                request('/delete_cropped/?uuid='+{uuids:blankUuids.map(doc=>doc.uuid)})
            }
            
            
            update();
        } else {
            showToast('Došlo k chybě')
        }
         
    };

  
    const handleSubmit = (e) => {
      e.preventDefault();
    };

        
    if (docs && docs[0] && docs[0].uuid &&requestSent===false) {
        /*request('/get_num_pages/?uuid=' + docs[0].uuid)
            .then(response => { 
                setPageCount(response.body);
            })
            .catch(error => {
                // Handle error if necessary
                console.error('Error fetching page count:', error);
            });*/
        const url = '/get_min_num_pages/';
        const data = {
            uuids: docs.map(doc => doc.uuid),
        };
        request(url, 'POST', JSON.stringify(data)).then(response => { 
            setPageCount(response.body["min_num_pages"]);
            setRequestSent(true)
            //console.log("min num pages " , pageCount)
        })
    }
    
   

    if ( pageTo === 0 && pageCount!==-1 && pageCount!==pageTo ){
        setPageTo(pageCount)
        setoutPageTo(pageCount)
    }
    


    if (parseInt(pageCount)>0 ){
        
        if (pageFrom > pageTo || pageFrom<1 || pageTo<1 || pageTo>pageCount){
            if (buttonDisabled!==true){setButtonDisabled(true)}
        }
        else{
            if (buttonDisabled!==false){setButtonDisabled(false)}
        }
       
    }
    
    let s = "Vytěžit"
    if (countFromIsdoc>0){s="Vytěžit vše"}

    if (buttonTitle!==s) {buttonTitle=s}

    
    let title= 'Vytěžit metadata ('+allDocs+' dokladů)'
    if (allDocs>1 && allDocs<=4){
        title= 'Vytěžit metadata ('+allDocs+' doklady)'
    }

    
    
    return (
        <div className={styles.ontop}>
        <Modal name={docs.length === 1 ? docs[0].name : title} close={close}>
            
                    {capturedMessage!=="" && (
                        <p className={styles.body}>
                        {capturedMessage}
                        <div style={{ maxHeight: '10em', maxWidth: '25rem', overflowY: 'auto', overflowX: 'auto' }}>
                        {capturedUuids.map((uuid, index) => (
                            <p key={index}>• {uuid}</p>
                        ))}
                        </div>
                        </p>
                    )}
                

            {multimessage!=="" && (
            <p className={styles.body}>
                {message!=="" &&message}
                    {multimessage}
                    <div style={{ maxHeight: '10em', maxWidth: '25rem', overflowY: 'auto', overflowX: 'auto' }}>
                    {isdocUuids.map((uuid, index) => (
                        <p key={index}>• {uuid}</p>
                    ))}
                    </div>
                    </p>
                )}
            

            {
            <div className={styles.body}>
            <div style={{ display: 'flex', gap: '10px',minWidth:"20rem" }}>
                    <label>
                    <input
                        type="radio"
                        value="off"
                        checked={selectPages === false}
                        onChange={handleChange}
                    />
                    {allDocs===1? "Celý doklad" : "Celé doklady"}
                    </label>

                    <label>
                    <input
                        type="radio"
                        value="on"
                        checked={selectPages === true}
                        onChange={handleChange}
                    />
                    Vybrané strany
                    </label>
                </div>
                </div>
            }
            <div style={{ display: 'flex', gap: '10px',minWidth:"20rem" }}>
            { (selectPages===true)&&
                <div className={styles.body} style={{ display: 'flex', gap: '10px',minWidth:"20rem" }}>
                <form onSubmit={handleSubmit} style={{ display: 'flex', alignItems: 'center' }}>
                    <label style={{ marginRight: '10px' }}>Od strany:</label>
                    <input
                        type="number"
                        value={pageFrom}
                        onChange={(e) => {
                            let value = parseInt(e.target.value);
                                if (value===0){value=1}
                                setPageFrom(value);
                                if (value <= parseInt(pageCount) && value >= 1) {
                                    setoutPageFrom(value)
                                }
                            }}
                        required
                        style={{ marginRight: '10px', width: '50px' }}
                    />
                    <label style={{ marginRight: '10px' }}>do strany</label>
                    <input
                        type="number"
                        value={pageTo}
                        onChange={(e) => {
                            const value = parseInt(e.target.value);
                                setPageTo(value);
                                if (value <= parseInt(pageCount) && value >= 1) {
                                    setoutPageTo(value)
                                }
                            }}
                        required
                        style={{ marginRight: '10px', width: '50px' }}
                    />
                    </form>
            </div>
            }
            </div>

            <div className={styles.buttonsCapture} >
                <Button onClick={close}>Zavřít</Button>
                { (countFromIsdoc>0 &&docs.length>1)  && <Button primary disabled={buttonDisabled}   onClick={handleClickOnlyEmpty}>{"Bez ISDOC ("+(blankUuids.length)+")"}</Button>}
                <Button primary disabled={buttonDisabled} onClick={handleClick}>{buttonTitle + (allDocs === 1 ? "" : " ("+allDocs+")")}</Button>
            </div>
        </Modal>
        </div>
    )
};