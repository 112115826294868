import React from 'react';
import {useLocation} from 'react-router-dom';

import {DocumentsTableHeader} from 'components/DocumentsTableHeader';
import {DocumentsTableRow} from 'components/DocumentsTableRow';

import {SummaryRow} from 'components/SummaryRow';

import styles from 'css_modules/DocumentsTable.module.css';
import {ProgressSpinner} from "./ProgressSpinner";

export const DocumentsTable = (
    {
        checkedDocs, docs, focusedDocId, mainChecked, setCheckedDocs, setMainChecked, setOrdering, sortDocs,
        visibleState, user, fields, visibleFields, loading, ordering,docsCustomTypes, ...tableRowProps
    }
) => {
    let docuuids = []
    docs.forEach(e => {
        docuuids.push(e.uuid)
    })


    const location = useLocation();

    let visibleCheckbox =  true //user.role !== 'OrganizationUnitAdmin' //&& location.pathname !== '/archive'
    if (location.pathname === '/my_templates'){
      //visibleCheckbox=false
    }
    const getTableClassName = () => {
        if (!visibleCheckbox) return styles.tableWithoutCheckbox;
        if (!visibleState) return styles.tableWithoutState;
        return styles.tableFull
    };

    const toggleCheckbox = (event) => {
        setMainChecked(event.target.checked);
        const activeDocs =
            docs.filter(doc => (location.pathname === '/documents' || doc.is_active || location.pathname === '/archive' || location.pathname === '/my_templates') && !doc.awaiting_capture);
        setCheckedDocs(event.target.checked ? activeDocs.map(doc => doc.uuid) : []);
    };

    const ormFormat = (str) => {
        let ormFormatedStr = str.replace("document_info", 'info').replace('.', '__');
        if(str === 'note'){
            ormFormatedStr = 'info__note';
        }
        return ormFormatedStr;
    }

    const drawHeader = () => {
        let columns = [];

        visibleFields.forEach((item) => {
            let keys = item.split(/\.(.*)/);
            let selected = 0;
            if (ordering.endsWith(ormFormat(keys[1])) && !ordering.startsWith('-')    ) {
                selected = -1
            }
            if (ordering.endsWith(ormFormat(keys[1])) && ordering.startsWith('-')    ) {
                selected = 1
            }

            columns.push(<DocumentsTableHeader key={'h' + keys[1]} setOrdering={setOrdering}  ordering={ordering} orderBy={ormFormat(keys[1])} selected={selected}>{fields[keys[0]].items[keys[1]].name}</DocumentsTableHeader>);
            
        });

        return columns;
    }

    const styleColumns = () => {
        let columnWidths = [];
        visibleFields.forEach((item) => {
            let keys = item.split(/\.(.*)/);
            columnWidths.push(fields[keys[0]].items[keys[1]].width);
        });

        return {gridTemplateColumns: (visibleCheckbox ? '1.75rem ' : '') + columnWidths.join(' ') + ' 1.75rem'};
    }
    
  
    
    
    if (loading) {
        return <ProgressSpinner/>
    }

    return (
        <>
        
        <table className={getTableClassName()} style={styleColumns()}>
            <tbody>
            <tr>
                {visibleCheckbox &&
                <td className={styles.checkboxCell}>
                    <input type="checkbox" checked={mainChecked} onChange={toggleCheckbox}/>
                </td>
                }
                {drawHeader()}
                <td></td>
            </tr>
            </tbody>
        </table>
            {docs.length !== 10 && (
                <>
                <div className={styles.content}>
                  <table className={styles.tableBodyScrollable} style={styleColumns()}>
                    <tbody>
                      {docs.map((doc, index) => (
                        <DocumentsTableRow
                          key={doc.uuid}
                          doc={doc}
                          index={index / docs.length}
                          docsCustomTypes={docsCustomTypes}
                          focused={focusedDocId === doc.uuid}
                          visibleCheckbox={visibleCheckbox}
                          visibleState={visibleState}
                          checkedDocs={checkedDocs}
                          setCheckedDocs={setCheckedDocs}
                          setMainChecked={setMainChecked}
                          user={user}
                          fields={fields}
                          visibleFields={visibleFields}
                          {...tableRowProps}
                        />
                      ))}
                    </tbody>
                  </table>
              
                  
               
                 </div>
                <table className={styles.summaryRow} style={styleColumns()}>
                    <tbody>
                      <SummaryRow 
                        docs={docs}
                        visibleFields={visibleFields}
                        visibleCheckbox={visibleCheckbox}
                      />
                    </tbody>
                  </table>
                  </>
              )}

              
              
              {docs.length === 10 && (
                <div className={styles.content}>
                  <table className={styles.tableBodyScrollable} style={styleColumns()}>
                    <tbody>
                      {docs.map((doc, index) => (
                        <DocumentsTableRow
                          key={doc.uuid}
                          doc={doc}
                          index={index / docs.length}
                          docsCustomTypes={docsCustomTypes}
                          focused={focusedDocId === doc.uuid}
                          visibleCheckbox={visibleCheckbox}
                          visibleState={visibleState}
                          checkedDocs={checkedDocs}
                          setCheckedDocs={setCheckedDocs}
                          setMainChecked={setMainChecked}
                          user={user}
                          fields={fields}
                          visibleFields={visibleFields}
                          forceDirection={true}
                          {...tableRowProps}
                        />
                      ))}
                    </tbody>
                  </table>
              
                  <table className={styles.summaryRow} style={styleColumns()}>
                    <tbody>
                      <SummaryRow 
                        docs={docs}
                        visibleFields={visibleFields}
                        visibleCheckbox={visibleCheckbox}
                      />
                    </tbody>
                  </table>
                  
                </div>
              )}

                
        
        </>
    )
};
// 